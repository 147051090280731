<template>
  <div>
    <div>
      <label for="exampleFormControlSelect1"
        ><h5>
          * <span @click="pageRefresh()">등록 리스트(최신등록순)</span>
        </h5></label
      >

      <div class="tab-content" id="myTabContent">
        <!-- 부서(팀)검색 -->
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <!-- 리스트 시작 -->
          <div class="accordion" id="accordionExample">
            <div
              class="card"
              v-for="manageList in getManageList"
              :key="manageList.id"
            >
              <div
                class="card-header"
                :id="'headingOne' + manageList.id"
                @click="getStatusList(manageList)"
              >
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseOne' + manageList.id"
                    aria-expanded="true"
                    :aria-controls="'#collapseOne' + manageList.id"
                    style="text-decoration-line: none"
                  >
                    <span style="color: #1d2124">
                      {{ getmomenttime(manageList.regdate) }} </span
                    ><span style="color: #dee2e6">|</span>
                    {{ manageList.membername }}
                    <span style="color: #dee2e6">|</span>
                    <span style="color: #1d2124"> {{ manageList.dept }}</span>
                  </button>
                </h2>
              </div>

              <div
                :id="'collapseOne' + manageList.id"
                class="collapse"
                :aria-labelledby="'headingOne' + manageList.id"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      style="background: ghostwhite"
                    ></li>
                    <li class="list-group-item">
                      <b>1. 구분:</b>
                      {{ getSector(manageList.sector) }}
                    </li>
                    <li class="list-group-item">
                      <b>2. 백신 접종:</b>
                      {{ getVaccineCode(manageList.vaccine) }}
                    </li>
                    <li class="list-group-item">
                      <b>3. 발생 경로</b>: {{ manageList.path }}
                    </li>
                    <!-- <li class="list-group-item">3. 전파자: {{manageList.spreader}}</li> -->
                    <li class="list-group-item">
                      <b>4. 최초 PL 의견:</b>
                      <p v-html="getHtmlConvert(manageList.pl)"></p>
                    </li>
                    <li class="list-group-item" v-if="manageList.spreader">
                      <b>5. 주변 근무자:</b>
                      <span
                        v-for="near in getNearMemberSMS(manageList.spreader)"
                        :key="near.id"
                      >
                        <span v-if="grade > 1" @click="setPlAlarm(near, manageList.membername)">{{ near }}</span>
                        <span v-else>{{ near }}</span>
                      </span>
                    </li>
                  </ul>
                  <div style="text-align: center; margin-top: 10px">
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      @click="SetPlOpinion(manageList)"
                    >
                      PL 의견 등록
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-success"
                      @click="SetPmoOpinion(manageList)"
                      style="margin-left: 30px"
                    >
                      PMO 가이드
                    </button>
                  </div>
                  <table
                    class="table table-bordered table-hover"
                    style="margin-top: 12px"
                  >
                    <thead>
                      <tr>
                        <th scope="col-3">등록일</th>
                        <th scope="col-3">구분</th>
                        <th scope="col-3">의견</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="statusList in statusLists"
                        :key="statusList.id"
                      >
                        <td colspan="3" class=":not">
                          <table>
                            <tr style="background: aliceblue">
                              <td>단계</td>
                              <td colspan="2">
                                {{ getProcessDetail(statusList.sector) }}
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 25%">
                                {{ getmomenttime(statusList.regdate) }}
                              </td>
                              <td style="width: 10%">
                                {{ statusList.opndiv
                                }}<span
                                  style="font-size: 10px"
                                  v-if="statusList.writer"
                                >
                                  ({{ statusList.writer }})</span
                                >
                              </td>
                              <td>
                                <p
                                  v-html="getHtmlConvert(statusList.opinion)"
                                ></p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- 리스트 종료 -->
          <!-- pgaination -->
          <div style="padding-top: 20px">
            <paginate
              :pageCount="pageTotalCount"
              :clickHandler="clickCallback"
              :prevText="'Prev'"
              :nextText="'Next'"
              :containerClass="'pagination'"
            >
            </paginate>
          </div>
          <!-- pgaination -->
          <label class="form-label" style="margin-left: 20px"
            >최신 등록순으로 50개만 보여집니다.</label
          >
        </div>
      </div>


  <!-- 주변근무자 통보 메시지  -->
<div class="modal fade" id="nearPeopletoMsg" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="nearPeopletoMsgLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="nearPeopletoMsgLabel">PL 통보</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <p> {{ sendMsgToPL }}</p>
          <p> 수신자(PL):  {{ sendPlList.remark }} </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="SendToPLAlarm(sendPlList.remark, sendMsgToPL)">발송</button>
                    <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="modalClose(this)"
            >
              Close
            </button>
        
      </div>
    </div>
  </div>
</div>

    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form
            name="regManageForm"
            v-on:submit="submitManageForm"
            id="regManageForm"
          >
            <div class="modal-header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style="color: blue"
              >
                <span id="plpmo"></span>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="modalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <input type="hidden" name="opndiv" id="opndiv" v-model="opndiv" />
            <input
              type="hidden"
              name="memberno"
              id="memberno"
              v-model="PersonGetData.memberno"
            />
            <input
              type="hidden"
              name="membername"
              id="membername"
              v-model="PersonGetData.membername"
            />
            <input
              type="hidden"
              name="dept"
              id="dept"
              v-model="PersonGetData.dept"
            />
            <input
              type="hidden"
              name="parentId"
              id="parentId"
              v-model="PersonGetData.id"
            />
            <input type="hidden" name="writer" id="writer" v-model="writer" />
            <div class="modal-body">
              <div class="form-group">
                <label for="recipient-name" class="col-form-label"
                  ><b>진행 상황:</b></label
                >
                <select
                  class="form-control"
                  id="sector"
                  name="sector"
                  v-model="sector"
                >
                  <option
                    v-for="processCategory in processCategorys"
                    :key="processCategory.id"
                    v-bind:value="processCategory.codeno"
                    :id="processCategory.codenno"
                  >
                    {{ processCategory.codename }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="message-text" class="col-form-label"
                  ><b>의견 내용:</b></label
                >
                <textarea
                  class="form-control"
                  id="opinion"
                  name="opinion"
                  rows="5"
                  v-model="opinion"
                  placeholder="진행 상태에 따른 상세 설명을 기록해주세요."
                ></textarea>
              </div>
              <div class="form-group" v-show="plpmodiv">
                <label for="message-text" class="col-form-label"
                  >SMS 발송 - PL :</label
                >
                <input
                  type="checkbox"
                  name="smsyn"
                  id="smsyn"
                  data-toggle="toggle"
                  data-size="lg"
                  style="margin-left: 10px"
                />

                <label
                  for="message-text"
                  style="margin-left: 20px"
                  class="col-form-label"
                  >대상자 :{{ receiveMembername }}</label
                >
                <input
                  type="checkbox"
                  name="membersmsyn"
                  id="membersmsyn"
                  data-toggle="toggle"
                  data-size="lg"
                  style="margin-left: 10px"
                />

                <input
                  type="hidden"
                  name="plphonenumber"
                  id="plphonenumber"
                  v-model="plphonenumber"
                />
                <input
                  type="hidden"
                  name="membertel"
                  id="membertel"
                  v-model="PersonGetData.tel"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">등록</button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import manageService from "../services/manageService";
import statusService from "../services/statusService";
import codeService from "../services/codeService";
import moment from "moment";
import $ from "jquery";

import seatmapService from "../services/seatmapService";

export default {
  name: "list",
  components: {},
  data() {
    return {
      sectorcate: "",
      title: "",
      manageLists: {},
      deptCategorys: {},
      currentIndex: -1,
      submitted: false,
      farmcode: localStorage.getItem("logininfo_farmcode"),
      deptCategory: "",
      currentDept: "팀(부서)를 선택하세요.",
      memberLists: [],
      isModalViewed: false,
      PersonGetData: {},
      showModal: false,
      statusLists: [],
      opndiv: "",
      sectorCategorys: [],
      processCategorys: [],
      opinion: "",
      processSectorName: "",
      dept: "",
      manageDeptLists: {},
      plpmodiv: false,
      plphonenumber: "",
      receiveMembername: "",

      page: 9, //보여줄 항목
      current_page: 0,
      manageListLimit: {},
      pageTotalCount: 0,
      writer:
        localStorage.getItem("membername") != null
          ? localStorage.getItem("membername")
          : this.userInfo.membername,
      nearMember: [],
      sendMsgToPL : "",
      sendPlList : {},
      grade:localStorage.getItem("grade") != null ? localStorage.getItem("grade") : this.userInfo.grade,
    };
  },
  created() {
    this.getMgmtAllList("30");
    this.getSectorCategory();
    this.getProcessCategory();
  },
  computed: {
    getManageList: function () {
      return this.manageLists;
    },

    getManageDeptList: function () {
      return this.manageDeptLists;
    },
  },
  methods: {
    //SMS 발송
    SendToPLAlarm(phone, msg){

        let formdata = new FormData();
        formdata.append("sender", "01053731570"); // text data
        formdata.append("receiver",phone); // binary data
        formdata.append("msg",msg);
        formdata.append("msg_type","SMS");
        formdata.append("title","주변근무자 등록 갱신 안내");
        formdata.append("destination","");

          manageService
          .sendToPLMsg(formdata)
          .then(() => {
            this.message = "";
            this.$toast.show("발송되었습니다.", {
              type: "warning",
              position: "top-right",
            });
          })
          .catch((e) => {
            this.$toast.show("발송되지 않았습니다.", {
              type: "success",
              position: "top-right",
            });
            console.log(e);
          });

          $("#nearPeopletoMsg").modal("hide");

    },
    //주변근무자 모달
    modalClose() {
      $("#nearPeopletoMsg").modal("hide");
      
    },    
    //주변근무자 소속 PL 메시지 발송
    setPlAlarm(item, targetnm) {
      let trimname = item.replace(/(\s*)/g, "");
      //console.log("send=", item);
      const data = {
        display : item,
      }

      seatmapService
        .getFindPL(data)
        .then((response) => {        
          this.sendPlList = response.data[0]; 
          //console.log(">>>", this.sendPlList)

          if(response.data.length == 0 || response.data[0] == null || typeof response.data[0] == "undefined" ){      
            this.sendMsgToPL = "정보가 부족합니다. 관리자에게 문의해주세요.";
            this.sendPlList = {
              remark : "",              
            };
           //console.log(this.sendMsgToPL,response);
            $("#nearPeopletoMsg").modal("show");
          } else {
              this.sendMsgToPL = "[PMO] "+targetnm+"님의 주변근무자 "+trimname+"님의 최신 PL의견을 등록 부탁드립니다.";
              $("#nearPeopletoMsg").modal("show"); 
          }
        })
        .catch((e) => {
          console.log(e);
        });

    },
    //주변근무자 SMS 발송
    getNearMemberSMS: function (item) {
      return item.split(",");
    },
    //page refresh
    pageRefresh() {
      this.renderComponent = true;
    },
    //pagenation
    clickCallback(pageNum) {
      const startPage = (pageNum - 1) * this.page;
      const endPage = pageNum * this.page - 1;
      this.manageLists = this.manageListLimit.slice(startPage, endPage);
      //console.log(this.manageLists);
    },
    //페이지
    //개행문자 인식
    getHtmlConvert(str) {
      const replacer = new RegExp("\\n", "g");
      return str.replace(replacer, "<br />");
    },
    //전체 리스트 가져오기
    getMgmtAllList(item) {
      this.sector = item;
      const data = {
        sector: "30",
      };

      manageService
        .getAllList(data)
        .then((response) => {
          //console.log(response.data);
          //this.manageLists = response.data;
          this.manageLists = response.data.slice(0, this.page);
          //console.log( this.nearMember);
          //this.manageLists = response.data;
          this.manageListLimit = response.data;
          this.pageTotalCount = Math.ceil(response.data.length / this.page);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //백신 코드
    getVaccineCode(vcode) {
      if (vcode == "20") return "미접종";
      if (vcode == "21") return "1차";
      if (vcode == "22") return "2차";
      if (vcode == "23") return "3차";
      if (vcode == "24") return "4차";
      return "";
    },
    //상태 기록 리스트 가져오기
    getStatusList(item) {
      const data = {
        memberno: item.memberno,
        parentId: item.id,
      };

      statusService
        .getList(data)
        .then((response) => {
          this.statusLists = response.data;
          //console.log(this.statusLists);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //의견 등록 저장
    submitManageForm(e) {
      e.preventDefault();
      var object = {};
      var fform = document.getElementById("regManageForm");
      var fsector = document.getElementById("sector").value;

      //PL 의견 등록 시 SMS
      var smsyn = document.getElementById("smsyn").checked;
      var fphonenumber = document.getElementById("plphonenumber").value;

      //대상사 의견 등록 시 SMS
      var membersmsyn = document.getElementById("membersmsyn").checked;
      var fmembertel = document.getElementById("membertel").value;

      //의견 등록
      var fopinion = document.getElementById("opinion").value;
      fopinion = fopinion.substr(0, 40);

      var form = new FormData(fform);
      form.forEach(function (value, key) {
        object[key] = value;
      });

      object["sector"] = fsector;

      //PL 의견 등록 시 SMS
      if (smsyn == true) object["smsyn"] = "Y";
      else object["smsyn"] = "N";

      //대상사 의견 등록 시 SMS
      if (membersmsyn == true) object["membersmsyn"] = "Y";
      else object["membersmsyn"] = "N";

      //SMS 발송값 PL만
      if (smsyn == true && membersmsyn == false) {
        object["sender"] = "01053731570";
        object["receiver"] = fphonenumber.trim();
        object["msg"] = this.receiveMembername + "님," + fopinion;
        object["msg_type"] = "SMS";
        object["title"] = "PMO 가이드";
      }

      //SMS 발송값  PL + 대상자
      if (smsyn == true && membersmsyn == true) {
        object["sender"] = "01053731570";
        object["rec_1"] = fphonenumber.trim();
        object["msg_1"] = this.receiveMembername + "님," + fopinion;
        object["msg_type"] = "SMS";
        object["rec_2"] = fmembertel.trim();
        object["msg_2"] = "[발신]" + fopinion;
        object["cnt"] = "2";
        object["title"] = "PMO 가이드";
      }

      //SMS 발송값 대상자만
      if (smsyn == false && membersmsyn == true) {
        object["sender"] = "01053731570";
        object["receiver"] = fmembertel.trim();
        object["msg"] = "[발신]" + fopinion;
        object["msg_type"] = "SMS";
        object["title"] = "PMO가이드";
      }

      //   sender: 발신자 전화번호 // (최대 16bytes)
      //   rec_1: 수신자 전화번호1
      //   msg_1: 메시지 내용1	// (1~2,000Byte)
      //   msg_type: SMS(단문), LMS(장문), MMS(그림문자)
      //   cnt: 메세지 전송건수 // (1~500)

      var jsondata = JSON.stringify(object);

      if (fsector == "") {
        this.$toast.show("현재 상황을 선택해주세요.", {
          type: "success",
          position: "top-right",
        });
        return;
      }

      if (JSON.parse(jsondata).opinion == "") {
        this.$toast.show("의견을 등록해주세요.", {
          type: "success",
          position: "top-right",
        });
        return;
      }
      statusService
        .insert(jsondata)
        .then(() => {
          // console.log(response.data);
          this.message = "";
          this.$toast.show("저장되었습니다.", {
            type: "warning",
            position: "top-right",
          });

          const reData = {
            memberno: object.memberno,
            id: object.parentId,
          };

          this.getStatusList(reData);
          $("#exampleModal").modal("hide");
        })
        .catch((e) => {
          this.$toast.show("다시 확인해주세요.", {
            type: "success",
            position: "top-right",
          });
          console.log(e);
        });
    },

    SetPlOpinion(item) {
      this.opinion = "";
      this.plpmodiv = false;
      this.getProcessCategory();
      this.PersonGetData = item;
      this.plphonenumber = item.pltel;
      $("#plpmo").html("PL 의견 등록");
      this.opndiv = "PL";
      $("#exampleModal").modal("show");
    },
    SetPmoOpinion(item) {
      this.opinion = "";
      this.plpmodiv = true;
      this.getProcessCategory();
      this.PersonGetData = item;
      this.plphonenumber = item.pltel;
      this.receiveMembername = item.membername;
      $("#plpmo").html("PMO 가이드");
      this.opndiv = "PMO";
      $("#exampleModal").modal("show");
    },

    getSector(item) {
      let isSector = "";
      for (var i = 0; i < this.sectorCategorys.length; i++) {
        if (this.sectorCategorys[i]["codeno"] == item) {
          isSector = this.sectorCategorys[i]["codename"];
          break;
        }
      }
      return isSector;
      // let sector = "";
      // const data = {
      //   grp: "100",
      //   codeno: item, //감염발생구분
      // };
      // codeService
      //   .getListDetail(data)
      //   .then((response) => {
      //     sector = response.data;
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      // return sector;
    },
    getmoment: function (ddate) {
      return moment(ddate).format("MM.DD");
    },
    getmomenttime: function (ddate) {
      return moment(ddate).format("MM.DD HH:mm");
    },

    //단계별 조회
    onChange() {
      const data = {
        sector: this.sectorcate,
      };

      // console.log(data);

      manageService
        .getList(data)
        .then((response) => {
          this.manageLists = response.data;
          //console.log("manageList ==> " + JSON.stringify(this.manageLists));
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //부서 팀별 조회
    changeDept() {
      const data = {
        dept: "30",
      };
      manageService
        .getDeptList(data)
        .then((response) => {
          this.manageDeptLists = response.data;
          // console.log(
          //   "manageDeptLists ==> " + JSON.stringify(this.manageDeptLists)
          // );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    trgtRegister(memberList) {
      this.$router.push({
        name: "trgtRegister",
        params: {
          memberno: memberList.memberno,
          membername: memberList.membername,
          dept: memberList.dept,
          tel: memberList.tel,
        },
      });
    },
    updateTutorial(tutorial) {
      //e.preventDefault();
      // let myForm = document.getElementById("checkForm")
      // let form = new FormData(myForm);

      const data = {
        id: tutorial.id,
        words: tutorial.words,
        pray: tutorial.pray,
        opinon: tutorial.opinon,
      };
      // need to convert it before using not with XMLHttpRequest
      // for (let [key, val] of form.entries()) {
      //   Object.assign(data, { [key]: val })
      //  // console.log({ [key]: val })
      // }
      //console.log("tutorial=="+tutorial.words)
      //console.log(...form.entries())
      if (tutorial.words || tutorial.pray) {
        manageService
          .update(tutorial.id, data)
          .then(() => {
            // console.log(response.data);
            this.message = "";
            this.$toast.show("저장되었습니다.", {
              type: "warning",
              position: "top-right",
            });
          })
          .catch((e) => {
            this.$toast.show("다시 확인해주세요.", {
              type: "success",
              position: "top-right",
            });
            console.log(e);
          });
      } else {
        this.$toast.show("입력 내용이 없습니다.", {
          type: "success",
          position: "top-right",
        });
      }
    },
    // 진행사항코드 가져오기
    getProcessCategory() {
      const data = {
        grp: "300", //감염발생구분
      };
      codeService
        .getList(data)
        .then((response) => {
          this.processCategorys = response.data;
          //console.log("processCategorys ==> " + JSON.stringify(response.data));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 진행사항코드 상세정보 가져오기
    getProcessDetail(item) {
      let isCategory = "";
      for (var i = 0; i < this.processCategorys.length; i++) {
        if (this.processCategorys[i]["codeno"] == item) {
          isCategory = this.processCategorys[i]["codename"];
          break;
        }
      }
      return isCategory;

      // codeService
      //   .getListDetail(data)
      //   .then((response) => {
      //     sectorname = response.data[0].codename;
      //     console.log("processCategorys ==> " +  sectorname);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      //   return sectorname;
    },
    // 감염 발생 경로
    getSectorCategory() {
      const data = {
        grp: "100", //감염발생구분
      };
      codeService
        .getList(data)
        .then((response) => {
          this.sectorCategorys = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    //this.retrieveDeptCategory();
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
.done {
  background: oldlace;
}
a {
  cursor: pointer;
}
.pagination {
  justify-content: center;
  flex-wrap: wrap;
}
</style>
